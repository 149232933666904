import { sendDataLayerEventUnsafe } from "./dataLayer";

const quickAdvancedSearch = document.getElementById("quick-search-advanced");
const searchResultAdvancedSearch = document.getElementById("advanced-search");
const searchResultFilter = document.getElementById(
  "advanced-search_filter-button"
);

if (quickAdvancedSearch) {
  quickAdvancedSearch.addEventListener("click", () => {
    pushGTMEvent("quick search");
  });
}

if (searchResultAdvancedSearch) {
  searchResultAdvancedSearch.addEventListener("click", () => {
    pushGTMEvent("search result page");
  });
}

if (searchResultFilter) {
  searchResultFilter.addEventListener("click", () => {
    pushGTMEvent("search result page (filter)");
  });
}

function pushGTMEvent(location: string) {
  sendDataLayerEventUnsafe("AdvancedSearchClicked", {
    AdvancedSearchClickedFrom: location,
  });
}
